import { InertiaApp } from '@inertiajs/inertia-vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './bootstrap';

Vue.use(InertiaApp);
Vue.use(Loading);

const app = document.getElementById('app')

window.Event = new Vue();

new Vue({
    render: h => h(InertiaApp, {
        props: {
          initialPage: JSON.parse(app.dataset.page),
          resolveComponent: name => import(`./Pages/${name}`).then(module => module.default),
        },
      }),
    created: function () {
        var loader = null;

        axios.interceptors.request.use(
            (config) => {
                // show loader, store instance
                loader = this.$loading.show()
                return config
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                // hide loader
                loader.hide()
                return response;
            },

            (error) => {
                // hide loader
                loader.hide()
                return Promise.reject(error);
            }
        );
    }
}).$mount(app)

Object.filter = (obj, predicate) =>
    Object.assign({}, ...Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .map(key => ({ [key]: obj[key] })));
